import React from "react"
// import FLayout from "../components/flayout";
import Login from "../components/login";

class LoginPage extends React.Component {
    render() {
        return (
            // <FLayout>
                <Login location={this.props.location} seoFields={seoFields}/>
            // </FLayout>
        )
    }
}

const seoFields = {
    description: "Login to your MProfit account to manage, renew or upgrade your MProfit plan, manage users associated with your account, update your profile and access your referral panel & order history.",
    // lang,
    // meta,
    // keywords,
    title: "Login",
}

export default LoginPage