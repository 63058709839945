import React from "react"
import LoginStructure from "../../shared/loginStructure";

const Login = ({location,seoFields}) => {
  return (
    <LoginStructure
      nextPage={'/account/plan'}
      showProceed={false}
      headingText={'Login to your account'}
      submitText={'Continue'}
      passwordField={'Password'}
      isTrial={false}
      trialSignUpLink={'/sign-up'}
      showCloudLoginLink={true}
      location={location}
      seoFields={seoFields}
    />
  )
}

export default Login;